import { UserResponsibilityQuestionsEnum } from "~/__generated__/graphql"
import { Control, useFormContext } from "react-hook-form"
import { CheckboxField } from "~/components/forms/CheckboxField"
import { FormValues } from "../ApplicationLandingScreen"
import { Translation } from "~/common/Translation"
import { camelCase } from "change-case"
import { useEffect } from "react"

interface ResponsibilityQuestionsProps {
  control: Control<FormValues>
  userResponsibilityQuestions: UserResponsibilityQuestionsEnum[]
}

// Create a type that extracts just the responsibilityQuestions keys
type ResponsibilityQuestionsKeys = keyof FormValues["responsibilityQuestions"]

export const ResponsibilityQuestions = ({
  control,
  userResponsibilityQuestions,
}: ResponsibilityQuestionsProps) => {
  const { watch, setValue, formState } = useFormContext()
  const noneOfTheAbove = watch("responsibilityQuestions.noneOfTheAbove")
  const hasAnsweredResponsibilityQuestions = userResponsibilityQuestions.some(
    (field) =>
      field !== UserResponsibilityQuestionsEnum.NoneOfTheAbove &&
      watch(`responsibilityQuestions.${camelCase(field)}`)
  )

  useEffect(() => {
    if (noneOfTheAbove) {
      userResponsibilityQuestions.forEach((field) => {
        if (field !== UserResponsibilityQuestionsEnum.NoneOfTheAbove) {
          const fieldName = camelCase(field) as ResponsibilityQuestionsKeys
          setValue(`responsibilityQuestions.${fieldName}`, false)
        }
      })
    }
  }, [noneOfTheAbove, userResponsibilityQuestions, setValue])

  useEffect(() => {
    if (hasAnsweredResponsibilityQuestions) {
      setValue("responsibilityQuestions.noneOfTheAbove", false)
    }
  }, [hasAnsweredResponsibilityQuestions, setValue])

  return (
    <>
      {formState.errors.responsibilityQuestions && (
        <p className="text-red-500 mb-4 text-sm">
          Please answer at least one responsibility question.
        </p>
      )}
      {userResponsibilityQuestions.map((field) => {
        const fieldName = camelCase(field) as ResponsibilityQuestionsKeys

        return (
          <CheckboxField
            containerClassName="border-none justify-start py-2 px-0 items-start"
            inputClassName="rounded-[2px] border border-slate-300"
            key={field}
            control={control}
            name={`responsibilityQuestions.${fieldName}`}
            label={
              <span>
                <Translation ns="responsibilityQuestions" i18nKey={field} />
              </span>
            }
            labelClassName="text-dark-gray leading-6"
          />
        )
      })}
    </>
  )
}
