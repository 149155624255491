import { RefObject, useEffect, useMemo, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { CommunitySlug } from "~/__generated__/graphql"
import {
  editIntroductionsPath,
  editNotificationsPath,
  editSubscriptionPath,
} from "~/common/paths"
import { cn } from "~/lib/utils"
import { useCommunity } from "~/community/useCommunity"
import ArrowLeft from "~/images/icons/arrow-left.svg?react"
import { IntroductionsSettingsModule } from "~/introduction-cycles/IntroductionsSettingsModule"
import { useAuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { Button, buttonVariants } from "~/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { SubscriptionManagementModule } from "~/subscriptions/SubscriptionManagementModule"
import { Link } from "~/ui/Link"
import { useForceUpdate } from "~/common/useForceUpdate"
import { FormProvider, useForm } from "react-hook-form"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "@apollo/client/core"
import { toast } from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { CheckboxField } from "~/components/forms/CheckboxField"

export const AccountSettingsScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const forceUpdate = useForceUpdate()
  const { currentUser } = useCurrentUser()

  interface IFormInput {
    discussions: boolean
    directMessaging: boolean
    library: boolean
  }

  const form = useForm<IFormInput>()

  useEffect(
    () =>
      form.reset(
        {
          discussions: currentUser.notificationPreferences?.discussions,
          directMessaging: currentUser.notificationPreferences?.directMessaging,
          library: currentUser.notificationPreferences?.library,
        },
        { keepDirtyValues: true }
      ),
    [currentUser, form]
  )

  const [runNotificationPreferencesUpdate, { loading: updating }] =
    useSafeMutation(NOTIFICATION_PREFERENCES_UPDATE_MUTATION)

  const onNotificationPreferencesSubmit = async (formData: IFormInput) => {
    const { errors } = await runNotificationPreferencesUpdate({
      variables: {
        input: formData,
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      toast.success("Notification preferences updated!")
      form.reset()
    }
  }

  const notificationPreferencesDirtyFieldsCount = useMemo(
    () => Object.keys(form.formState.dirtyFields).length,
    [form.formState]
  )

  const introductionsRef = useRef<HTMLDivElement>(null)
  const notificationsRef = useRef<HTMLDivElement>(null)
  const subscriptionRef = useRef<HTMLDivElement>(null)

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current)
      window.scrollTo({
        top: Math.max(0, ref.current.offsetTop - 30),
        behavior: "smooth",
      })
  }

  useEffect(() => {
    if (location.hash === "#introductions") scrollToRef(introductionsRef)
    if (location.hash === "#notifications") scrollToRef(notificationsRef)
    if (location.hash === "#subscription") scrollToRef(subscriptionRef)
  }, [location])

  const { setLeftSidebar, resetLeftSidebar } = useAuthenticatedLayout()

  useEffect(() => {
    setLeftSidebar(<AccountSettingsNavigation />)

    return () => {
      resetLeftSidebar()
    }
  }, [location, setLeftSidebar, resetLeftSidebar])

  // periodically rerender so we pick up changes to notification permissions.
  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate()
    }, 400)
    return () => clearInterval(interval)
  }, [forceUpdate])

  return (
    <div className="container mx-auto flex items-start tracking-[0.5px] mb-20 px-4 text-foreground">
      <div className="w-full flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft className="mr-3" /> Back
          </div>
        </div>

        <IntroductionsSettingsModule ref={introductionsRef} />

        <Card ref={notificationsRef}>
          <CardHeader>
            <CardTitle>Notifications</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            {!("Notification" in window) ? (
              <>Notifications not supported by this browser.</>
            ) : Notification.permission === "granted" ? (
              <>Notifications are enabled!</>
            ) : Notification.permission === "denied" ? (
              <>
                Notifications are disabled. Use your browser settings to enable
                them.
              </>
            ) : (
              <div>
                <div className="mb-2">
                  Would you like to enable notifications?
                </div>
                <div>
                  <Button onClick={() => Notification.requestPermission()}>
                    Enable notifications
                  </Button>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Manage Email Notification Preferences</CardTitle>
          </CardHeader>
          <CardContent className="pt-4">
            <FormProvider {...form}>
              <form
                id="notification-preferences"
                onSubmit={form.handleSubmit(onNotificationPreferencesSubmit)}
              >
                <div>
                  Select which email notifications you would like to{" "}
                  <strong>unsubscribe</strong> from
                </div>
                <div className="flex flex-col gap-2 mt-4">
                  <CheckboxField
                    containerClassName="border-none justify-start py-2 px-0 items-center"
                    inputClassName="rounded-[2px] border border-slate-300"
                    control={form.control}
                    label={
                      <span>
                        Discussions (sent when you are tagged in a post)
                      </span>
                    }
                    name="discussions"
                  />
                  <CheckboxField
                    containerClassName="border-none justify-start py-2 px-0 items-center"
                    inputClassName="rounded-[2px] border border-slate-300"
                    control={form.control}
                    label={
                      <span>
                        Direct Messaging (sent when a peer has DMed you or
                        replied to your DM)
                      </span>
                    }
                    name="directMessaging"
                  />
                  <CheckboxField
                    containerClassName="border-none justify-start py-2 px-0 items-center"
                    inputClassName="rounded-[2px] border border-slate-300"
                    control={form.control}
                    label={
                      <span>
                        Library (sent when new premium content has been added to
                        the Library)
                      </span>
                    }
                    name="library"
                  />
                </div>
                <Button
                  type="submit"
                  className="mt-6 mb-2 relative"
                  disabled={
                    updating || notificationPreferencesDirtyFieldsCount === 0
                  }
                >
                  Save changes
                </Button>
              </form>
            </FormProvider>
          </CardContent>
        </Card>

        <SubscriptionManagementModule ref={subscriptionRef} />
      </div>
    </div>
  )
}

export const AccountSettingsNavigation = () => {
  const community = useCommunity()

  return (
    <div className="flex flex-col gap-2 lg:mt-16 text-foreground">
      <Link variant="nav" to={editIntroductionsPath.pattern}>
        Introductions Settings
      </Link>
      <Link variant="nav" to={editNotificationsPath.pattern}>
        Notifications
      </Link>
      <Link variant="nav" to={editSubscriptionPath.pattern}>
        Manage Membership
      </Link>
      <Link
        className={cn(
          buttonVariants({ variant: "outline" }),
          "text-[12px] w-[143px] align-middle mr-2  pt-[11px] pb-[9px] font-semibold mt-10",
          {
            "border-white text-white hover:text-dark-gray":
              community.slug === CommunitySlug.Boardroom,
          }
        )}
        target="_blank"
        rel="noreferrer"
        href="mailto:memberships@workweek.com"
      >
        Contact Us
      </Link>
    </div>
  )
}

const NOTIFICATION_PREFERENCES_UPDATE_MUTATION = gql`
  mutation NotificationPreferencesUpdate(
    $input: NotificationPreferencesUpdateInput!
  ) {
    notificationPreferencesUpdate(input: $input) {
      user {
        id
        notificationPreferences {
          discussions
          directMessaging
          library
        }
      }
    }
  }
`
