import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import {
  feedPath,
  applicationLandingPath,
  applicationFitWaitingScreenPath,
} from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import { FeatureFlag } from "~/common/FeatureFlag"
import { FeatureFlagEnum } from "~/__generated__/graphql"

export const RequireUserOnboarding = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (!result.loading) {
      if (!FeatureFlag.get(FeatureFlagEnum.NewOnboarding)) {
        if (currentUser && currentUser.onboarded) {
          navigate(feedPath.pattern)
        }
      } else {
        if (currentUser) {
          navigate(applicationFitWaitingScreenPath.pattern)
        } else {
          navigate(applicationLandingPath.pattern)
        }
      }
    }
  }, [currentUser, result.loading, navigate])

  if (result.loading || !currentUser || currentUser.onboarded) {
    return null
  }

  return (
    <UserDialogContextProvider>
      <Outlet />
    </UserDialogContextProvider>
  )
}
