import { add, parseISO, startOfDay } from "date-fns"
import { forwardRef, useState } from "react"
import Toggle from "react-toggle"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { CalendarDateField } from "~/ui/CalendarDateField"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  FeatureFlagEnum,
  UserIntroductionsStateEnum,
} from "~/__generated__/graphql"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { Button } from "~/ui/button"
import { AlarmClock, HeartHandshake, Lock } from "lucide-react"
import { Switch } from "~/ui/switch"
import { FeatureFlag } from "~/common/FeatureFlag"
import { FieldPointer } from "~/ui/Onboarding"

export const useIntroductionsSnoozedUntilMutation = () => {
  const { currentUser } = useCurrentUser()
  const [date, setDate] = useState<Date | null>(
    currentUser.introductionsSnoozedUntil
      ? parseISO(currentUser.introductionsSnoozedUntil)
      : null
  )

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()

  const updateIntroductionsSnoozedUntil = async (newDate: Date | null) => {
    const previousDate = date
    setDate(newDate)
    const introductionsSnoozedUntilVal = newDate ? startOfDay(newDate) : null

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil: introductionsSnoozedUntilVal,
          introductionsState: introductionsSnoozedUntilVal
            ? UserIntroductionsStateEnum.Active
            : UserIntroductionsStateEnum.ActivelySnooze,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      setDate(previousDate)
    } else {
      if (introductionsSnoozedUntilVal) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      }
    }
  }

  return { updateIntroductionsSnoozedUntil, date, setDate }
}

export const useIntroductionsStateMutation = () => {
  const { currentUser } = useCurrentUser()
  const [date, setDate] = useState<Date | null>(
    currentUser.introductionsSnoozedUntil
      ? parseISO(currentUser.introductionsSnoozedUntil)
      : null
  )

  const [introductionsState, setIntroductionsState] =
    useState<UserIntroductionsStateEnum>(currentUser.introductionsState)

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()

  const updateIntroductionsState = async (
    newState: UserIntroductionsStateEnum,
    newDate: Date | null
  ) => {
    const previousState = introductionsState
    const previousDate = date
    setIntroductionsState(newState)
    setDate(newDate)

    if (!currentUser.onboardingActions?.optIntoIntroductions) {
      logEvent(AhoyEventTypeEnum.NewUserChecklistItemCompleted, {
        step_name: "Opt Into Intros",
      })
    }

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil: newDate ? startOfDay(newDate) : null,
          introductionsState: newState,
          ...(!currentUser.onboardingActions?.optIntoIntroductions && {
            optedIntoIntros: true,
          }),
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      setIntroductionsState(previousState)
      setDate(previousDate)
    } else {
      if (newState === UserIntroductionsStateEnum.ActivelySnooze) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      }
    }
  }

  return {
    updateIntroductionsState,
    introductionsState,
    date,
  }
}

export const IntroductionsSettingsModule = forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { updateIntroductionsSnoozedUntil, date } =
      useIntroductionsSnoozedUntilMutation()
    const {
      updateIntroductionsState,
      introductionsState,
      date: snoozeDate,
    } = useIntroductionsStateMutation()
    const { openSubscriptionWizard } = useSubscription()
    const { currentUser } = useCurrentUser()
    const canUseIntroductions = currentUser.permissions.canUseIntroductions
    const isNewOnboarding = FeatureFlag.get(FeatureFlagEnum.NewOnboarding)

    return (
      <Card ref={ref}>
        <CardHeader>
          <CardTitle>Introductions Settings</CardTitle>
        </CardHeader>
        <CardContent className="pt-4">
          {!isNewOnboarding && (
            <div className="mb-6">
              Manage your introductions to other members of the community.
            </div>
          )}
          {canUseIntroductions ? (
            <>
              {!isNewOnboarding && (
                <>
                  <div className="text-textLight font-semibold mb-2">
                    Snooze Introductions?
                  </div>
                  <div className="flex items-center gap-4 mb-4">
                    <Toggle
                      defaultChecked={!!date}
                      icons={false}
                      onChange={(e) => {
                        e.target.checked
                          ? updateIntroductionsSnoozedUntil(
                              add(new Date(), { months: 1 })
                            )
                          : updateIntroductionsSnoozedUntil(null)
                      }}
                    />
                    <div className="text-xs text-textTimestamp">
                      Snooze {date ? "ON" : "OFF"}
                    </div>
                  </div>
                </>
              )}
              {isNewOnboarding && (
                <>
                  <div className="text-textLight font-semibold mb-2">
                    Receiving curated introductions are a Plus Membership
                    benefit.
                  </div>
                  <div className="mb-4">
                    If you'd like to opt in, make sure your toggle is set to
                    "Participate." If you need to pause receiving introductions,
                    shift your toggle towards "Snooze." Your preference is saved
                    until you change it in the future.
                  </div>
                  <div className="relative">
                    <FieldPointer
                      show={
                        introductionsState ===
                        UserIntroductionsStateEnum.DefaultSnooze
                      }
                      className="before:top-1"
                    >
                      <Switch
                        className="border-input mb-4"
                        checked={
                          introductionsState ===
                          UserIntroductionsStateEnum.Active
                        }
                        onClick={(e) => {
                          ;[
                            UserIntroductionsStateEnum.DefaultSnooze,
                            UserIntroductionsStateEnum.ActivelySnooze,
                          ].includes(introductionsState)
                            ? updateIntroductionsState(
                                UserIntroductionsStateEnum.Active,
                                null
                              )
                            : updateIntroductionsState(
                                UserIntroductionsStateEnum.ActivelySnooze,
                                add(new Date(), { months: 1 })
                              )
                        }}
                        variant="selector"
                        size="xl"
                        options={[
                          <div className="flex items-center justify-center gap-1">
                            <AlarmClock className="w-4 h-4" />
                            Snooze
                          </div>,
                          <div className="flex items-center justify-center gap-1">
                            <HeartHandshake className="w-4 h-4" />
                            Participate
                          </div>,
                        ]}
                      />
                    </FieldPointer>
                  </div>
                </>
              )}
              {snoozeDate && (
                <>
                  <div className="text-textLight font-semibold mb-2">
                    Snooze Until?
                  </div>
                  <CalendarDateField
                    date={snoozeDate}
                    onChangeDate={(date) => {
                      if (date) {
                        updateIntroductionsState(
                          UserIntroductionsStateEnum.ActivelySnooze,
                          date
                        )
                      }
                    }}
                    fromYear={new Date().getFullYear()}
                    toYear={new Date().getFullYear() + 2}
                    disabled={{
                      before: new Date(),
                      after: add(new Date(), { years: 2 }),
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <Button
              type="button"
              onClick={() =>
                openSubscriptionWizard("PricingTableStep", {
                  source: "IntroductionSettingsModule",
                  requiredFeature: "canUseIntroductions",
                })
              }
            >
              Enable Introductions <Lock className="w-4 h-4 ml-1" />
            </Button>
          )}
          {!isNewOnboarding && canUseIntroductions && date && (
            <>
              <div className="text-textLight font-semibold mb-2">
                Snooze Until?
              </div>
              <CalendarDateField
                date={date}
                onChangeDate={(date) => {
                  if (date) {
                    updateIntroductionsSnoozedUntil(date || null)
                  }
                }}
                fromYear={new Date().getFullYear()}
                toYear={new Date().getFullYear() + 2}
                disabled={{
                  before: new Date(),
                  after: add(new Date(), { years: 2 }),
                }}
              />
            </>
          )}
        </CardContent>
      </Card>
    )
  }
)
