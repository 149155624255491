import { useState, Dispatch, SetStateAction, useMemo, useEffect } from "react"
import { cn } from "~/lib/utils"
import { useFormContext, Controller } from "react-hook-form"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { getMetaVar } from "~/common/getMetaVar"
import errorIcon from "~/images/error-screen.png"
import { SingleValue } from "react-select"
import { FieldPointer, OnboardingFieldLabel } from "~/ui/Onboarding"
import { useTranslation } from "react-i18next"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { Button, buttonVariants } from "~/ui/button"
import { Check, ChevronsUpDown, Sparkle, X } from "lucide-react"
import { Command, CommandGroup, CommandInput, CommandItem } from "~/ui/command"
import { FeatureFlag } from "~/common/FeatureFlag"
import { FeatureFlagEnum } from "~/__generated__/graphql"

const COMMON_PRONOUNS = ["She / Her", "He / Him", "They / Them"]

type PlaceValueType = {
  label: string
  value: google.maps.places.AutocompletePrediction | string
}

export const OnboardingDetailsFields = ({
  setCardLocation,
  displayPronouns = false,
}: {
  setCardLocation?: Dispatch<SetStateAction<string | null>>
  displayPronouns?: boolean
}) => {
  const {
    control,
    clearErrors,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext()
  const { currentUser } = useCurrentUser()
  const apiKey = getMetaVar("maps-api-key")
  const { t } = useTranslation("onboarding")

  const [enteredPronouns, setEnteredPronouns] = useState("")
  const [selectedPronouns, setSelectedPronouns] = useState(currentUser.pronouns)

  const displayedPronouns = useMemo(() => {
    return [
      selectedPronouns,
      currentUser.pronouns,
      ...COMMON_PRONOUNS,
      enteredPronouns,
    ]
      .filter((pronouns) => pronouns)
      .filter(
        (pronouns, index, self) => self.indexOf(pronouns) === index
      ) as string[]
  }, [enteredPronouns, selectedPronouns, currentUser.pronouns])

  // Jumping through some real hoops to avoid uncontrolled form errors
  // for no explicable reason
  const [placeValue, setPlaceValue] = useState<PlaceValueType>({
    value: currentUser.place?.id || "",
    label: currentUser.place?.full || "",
  })

  const onChangePlace = (option: SingleValue<PlaceValueType>) => {
    if (option) {
      if (typeof option.value == "string") {
        setValue("placeId", option.value, { shouldDirty: true })
        setPlaceValue(option)
      } else {
        setValue("placeId", option.value.place_id, { shouldDirty: true })
        setPlaceValue({ value: option.value.place_id, label: option.label })
        if (setCardLocation) {
          if (option.label.endsWith(", USA")) {
            setCardLocation(option.label.slice(0, -5))
          } else {
            setCardLocation(option.label.split(",").slice(-1)[0].trim())
          }
        }
      }
      clearErrors("placeId")
    } else {
      setValue("placeId", null, { shouldDirty: true })
      if (setCardLocation) {
        setCardLocation(null)
      }
      setPlaceValue({ value: "", label: "" })
    }
  }

  const [pronounsPickerOpen, setPronounsPickerOpen] = useState(false)
  useEffect(() => {
    if (!pronounsPickerOpen) {
      setEnteredPronouns("")
    }
  }, [pronounsPickerOpen])
  const onSelectPronouns = (pronouns: string) => {
    setSelectedPronouns(pronouns)
    setValue("pronouns", pronouns, {
      shouldDirty: currentUser.pronouns !== pronouns,
    })
    setPronounsPickerOpen(false)
  }

  const clearPronouns = (
    e: Pick<Event, "preventDefault" | "stopPropagation">
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedPronouns("")
    setEnteredPronouns("")
    setValue("pronouns", "", { shouldDirty: !!currentUser.pronouns })
  }

  const hasNewOnboarding = FeatureFlag.get(FeatureFlagEnum.NewOnboarding)

  return (
    <div className="grid gap-x-5 gap-y-4 grid-cols-2">
      {!!Object.keys(errors).length && (
        <div className="col-span-2 py-3 px-4 mb-4 flex bg-frenchRose bg-opacity-5 border-[1px] border-frenchRose text-[red] font-semibold items-center rounded-lg">
          <img src={errorIcon} alt="Error" className="mr-4 w-[44px] h-[44px]" />
          <div className="flex-1 text-left">
            Please correct the following:
            <div className="font-normal text-xs">
              {Object.values(errors)
                .map((fieldError) => fieldError?.message)
                .join(", ")}
              .
            </div>
          </div>
        </div>
      )}
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          First Name
          <span className="text-highlight">*</span>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn("onboarding-field__input text-dark-gray", {
            "has-error": !!errors.firstName,
          })}
          {...register("firstName", {
            required: "First Name is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          Last Name
          <span className="text-highlight">*</span>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn("onboarding-field__input text-dark-gray", {
            "has-error": !!errors.lastName,
          })}
          {...register("lastName", {
            required: "Last Name is required",
          })}
        />
      </label>
      {FeatureFlag.get(FeatureFlagEnum.Pronouns) && displayPronouns && (
        <label className="onboarding-field col-span-2">
          <OnboardingFieldLabel>Pronouns</OnboardingFieldLabel>
          <input
            type="hidden"
            {...register("pronouns")}
            value={selectedPronouns || ""}
          />
          <Popover
            open={pronounsPickerOpen}
            onOpenChange={setPronounsPickerOpen}
          >
            <PopoverTrigger asChild>
              <Button
                variant="input"
                role="combobox"
                className="text-md font-normal py-6"
              >
                <span className="overflow-hidden text-ellipsis whitespace-nowrap !inline-block">
                  {selectedPronouns || "Select or enter your pronouns"}
                </span>
                <div className="flex items-right items-center justify-center">
                  <div
                    className={cn(
                      buttonVariants({ variant: "subtle", size: "icon" }),
                      "ml-2 shrink-0",
                      selectedPronouns ? "opacity-100" : "opacity-0"
                    )}
                    onClick={clearPronouns}
                  >
                    <X className="w-5 h-5" />
                  </div>

                  <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
                </div>
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <Command>
                <CommandInput
                  icon={<></>}
                  value={enteredPronouns}
                  onValueChange={setEnteredPronouns}
                  placeholder="Select or enter your pronouns"
                />
                <CommandGroup>
                  {displayedPronouns.map((pronouns) => (
                    <CommandItem
                      key={pronouns}
                      onSelect={() => onSelectPronouns(pronouns)}
                      value={pronouns}
                    >
                      {!COMMON_PRONOUNS.includes(pronouns) &&
                      selectedPronouns !== pronouns ? (
                        <Sparkle className="w-5 h-5 mr-2" />
                      ) : (
                        <Check
                          className={cn(
                            "w-5 h-5 mr-2",
                            selectedPronouns === pronouns
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      )}
                      {pronouns}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </label>
      )}
      <label className="onboarding-field col-span-2">
        <OnboardingFieldLabel className="flex justify-between items-end">
          <div className="whitespace-nowrap mr-8">Email Address</div>
          <div className="text-[12px] font-normal text-right">
            *You can contact us if you'd like a different email associated with
            your membership
          </div>
        </OnboardingFieldLabel>
        <input
          className="onboarding-field__input text-dark-gray"
          disabled={true}
          value={currentUser.email || ""}
        />
      </label>
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          <FieldPointer show={!watch("companyName")}>
            Company
            <span className="text-highlight">*</span>
          </FieldPointer>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn("onboarding-field__input text-dark-gray", {
            "has-error": !!errors.companyName,
            "border-highlight bg-card-highlight-background":
              !watch("companyName") && hasNewOnboarding,
          })}
          {...register("companyName", {
            required: "Company is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2 sm:col-span-1">
        <OnboardingFieldLabel>
          <FieldPointer show={!watch("jobTitle")}>
            Title
            <span className="text-highlight">*</span>
          </FieldPointer>
        </OnboardingFieldLabel>
        <input
          maxLength={40}
          className={cn("onboarding-field__input text-dark-gray", {
            "has-error": !!errors.jobTitle,
            "border-highlight bg-card-highlight-background":
              !watch("jobTitle") && hasNewOnboarding,
          })}
          {...register("jobTitle", {
            required: "Title is required",
          })}
        />
      </label>
      <label className="onboarding-field col-span-2">
        <OnboardingFieldLabel>
          <FieldPointer show={!watch("placeId")}>
            {t("homeBaseLabel")}
            <span className="text-highlight">*</span>
          </FieldPointer>
        </OnboardingFieldLabel>
        <Controller
          name="placeId"
          rules={{
            required: "Homebase is required",
          }}
          control={control}
          render={({ field, fieldState }) => (
            <GooglePlacesAutocomplete
              apiKey={apiKey}
              autocompletionRequest={{
                types: ["(cities)"],
              }}
              selectProps={{
                ...field,
                value: placeValue,
                onChange: onChangePlace,
                isClearable: !!placeValue.value,
                classNames: {
                  container: () => "text-dark-gray",
                  control: () =>
                    cn("onboarding-select__control text-dark-gray", {
                      "has-error": !!fieldState.error,
                      "!border-highlight !bg-card-highlight-background":
                        !watch("placeId") && hasNewOnboarding,
                    }),
                },
                components: {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                },
                placeholder: "",
              }}
            />
          )}
        />
      </label>
    </div>
  )
}
