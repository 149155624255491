import { useEffect, useState } from "react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { FeatureFlag } from "~/common/FeatureFlag"
import { Navigate, useNavigate } from "react-router-dom"
import { FeatureFlagEnum } from "~/__generated__/graphql"
import { useExperiment } from "~/common/useExperiment"
import { Referral } from "./ABFitWaitingScreen/Referral"
import { Upload } from "./ABFitWaitingScreen/Upload"
import { Rejected } from "./ABFitWaitingScreen/Rejected"
import { Processing } from "./ABFitWaitingScreen/Processing"
import { getMetaVar } from "~/common/getMetaVar"
import { applicationMostExcitedAboutPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { Translation } from "~/common/Translation"
const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"

export const WaitingRoomHeader = () => {
  return (
    <>
      <h1 className="text-4xl mb-2 font-serif text-center">
        <Translation ns="fitScreen" i18nKey="title" />
      </h1>
      <h1 className="text-4xl mb-4 font-serif text-center">
        <Translation ns="fitScreen" i18nKey="subtitle" />
      </h1>
      <div className="font-sans font-light text-pretext-light-gray text-lg tracking-wide mb-6">
        <Translation ns="fitScreen" i18nKey="reviewTime" />
      </div>
    </>
  )
}

export const ABFitWaitingScreen = () => {
  const [variant, setVariant] = useState<string | null>(null)
  const { getOrAssignVariant } = useExperiment("waiting_room")
  const navigate = useNavigate()

  const { currentUser, startPolling, stopPolling } = useCurrentUser()
  const [timeRemaining, setTimeRemaining] = useState(5 * 60) // 5 minutes in seconds
  const [processingState, setProcessingState] = useState<
    "processing" | "rejected"
  >("processing")
  const isFit = currentUser.fit
  const fitApplicationSubmittedAt = currentUser.fitApplicationSubmittedAt
  const fitnessDecisionCommunicatedAt =
    currentUser.fitnessDecisionCommunicatedAt

  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  if (isFit && fitnessDecisionCommunicatedAt) {
    navigate(applicationMostExcitedAboutPath.pattern)
  }

  useEffect(() => {
    if (isFit && !fitnessDecisionCommunicatedAt) {
      startPolling(30000)
    } else if (isFit && fitnessDecisionCommunicatedAt) {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [
    fitnessDecisionCommunicatedAt,
    startPolling,
    stopPolling,
    navigate,
    isFit,
  ])

  useEffect(() => {
    if (FeatureFlag.get(FeatureFlagEnum.NewOnboarding) && isFit) {
      getOrAssignVariant().then(({ variant, wasCreated }) => {
        if (variant) {
          setVariant(variant)
        }
      })
    }
  }, [getOrAssignVariant, isFit])

  useEffect(() => {
    if (!isFit && processingState === "processing") {
      if (fitnessDecisionCommunicatedAt) {
        const decisionDate = new Date(fitnessDecisionCommunicatedAt)
        const thirtyMinutesAgo = new Date(Date.now() - 30 * 60 * 1000)

        if (decisionDate < thirtyMinutesAgo) {
          if (qaToolsEnabled) {
            console.log(
              "Fitness decision was communicated more than 30 minutes ago, showing rejected state"
            )
          }
          setProcessingState("rejected")
          return
        }
      }

      if (fitApplicationSubmittedAt) {
        const submittedDate = new Date(fitApplicationSubmittedAt)
        const thirtyMinutesAgo = new Date(Date.now() - 30 * 60 * 1000)

        if (submittedDate < thirtyMinutesAgo) {
          if (qaToolsEnabled) {
            console.log(
              "Application submitted more than 30 minutes ago, showing rejected state"
            )
          }
          setProcessingState("rejected")
          return
        }

        const remainingMs =
          submittedDate.getTime() + 30 * 60 * 1000 - Date.now()
        const remainingSecs = Math.max(0, Math.floor(remainingMs / 1000))
        setTimeRemaining(remainingSecs)

        const timer = setInterval(() => {
          setTimeRemaining((prev) => {
            if (prev <= 1) {
              setProcessingState("rejected")
              return 0
            }
            return prev - 1
          })
        }, 1000)

        return () => clearInterval(timer)
      }
    }
  }, [
    isFit,
    processingState,
    fitApplicationSubmittedAt,
    fitnessDecisionCommunicatedAt,
  ])

  if (!FeatureFlag.get(FeatureFlagEnum.NewOnboarding)) {
    return <Navigate to="/" replace />
  }

  if (isFit && !variant) return <div>Loading...</div>

  return (
    <div className="relative min-h-screen">
      <div className="flex flex-col items-center pb-32">
        {!isFit ? (
          processingState === "processing" ? (
            <Processing />
          ) : (
            <Rejected />
          )
        ) : variant === "refer_a_friend" ? (
          <Referral />
        ) : (
          <Upload />
        )}
      </div>

      {qaToolsEnabled && (
        <div className="fixed bottom-8 right-8">
          <div className="flex flex-col items-end space-y-4">
            <h3 className="text-sm font-medium text-gray-500">QA Tools</h3>
            <div className="flex flex-col space-y-2">
              {!isFit && (
                <>
                  <button
                    onClick={() =>
                      setProcessingState(
                        processingState === "processing"
                          ? "rejected"
                          : "processing"
                      )
                    }
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 text-sm"
                  >
                    Switch to{" "}
                    {processingState === "processing"
                      ? "Rejected"
                      : "Processing"}
                  </button>
                  {processingState === "processing" && (
                    <div className="text-sm text-gray-500">
                      Time until auto-reject: {timeRemaining}s
                    </div>
                  )}
                </>
              )}
              <button
                onClick={() => {
                  runUserUpdate({
                    variables: {
                      input: { fitnessDecisionCommunicatedAt: new Date() },
                    },
                  })
                }}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-gray-600 text-sm"
              >
                Simulate AppAcceptedEmailJob run
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
