import { Play } from "lucide-react"
import { useRef, useState } from "react"
import ReactPlayer from "react-player"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCommunity } from "~/community/useCommunity"
import { Button } from "~/ui/button"
import { Dialog, DialogContent, DialogFooter } from "~/ui/dialog"

export const GetStartedLink = () => {
  const { logEvent } = useLogEvent()
  const [isOpen, setIsOpen] = useState(false)
  const preventDuplicateRef = useRef(false)

  const onClick = () => {
    logEvent(
      AhoyEventTypeEnum.WelcomeVideoViewed,
      undefined,
      preventDuplicateRef
    )
    setIsOpen(true)
  }

  return (
    <>
      <Button
        variant="empty"
        className="flex items-center gap-1"
        onClick={onClick}
        type="button"
      >
        <span className="text-2xl">👋</span>
        <Play className="h-[1.2rem] w-[1.2rem] rounded-full border border-black p-1" />
      </Button>

      <GetStartedModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

const GetStartedModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}) => {
  const community = useCommunity()

  const onComplete = () => {
    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onOpenChange={onComplete}>
      <DialogContent className="gap-8 p-8">
        <ReactPlayer
          className="rounded-2xl overflow-hidden min-h-[340px]"
          height={"100%"}
          width={"100%"}
          controls={true}
          playing={true}
          url={
            community.welcomeVideoUrl ||
            "https://workweek-public.s3.us-east-2.amazonaws.com/web-app-v1.mp4"
          }
        />
        <DialogFooter className="flex justify-center">
          <Button onClick={onComplete} className="text-xs px-6 py-3.5">
            Go to Dashboard
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
