import { Search } from "lucide-react"
import { Input } from "~/ui/input"

type LeadsSearchProps = {
  searchTerm: string
  setSearchTerm: (term: string) => void
}

export const LeadsSearch = ({
  searchTerm,
  setSearchTerm,
}: LeadsSearchProps) => {
  return (
    <div className="relative flex-grow">
      <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        type="search"
        placeholder="Search leads..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="pl-8"
      />
    </div>
  )
}
