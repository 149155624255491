import { useEffect } from "react"
import { CommunitySlug, MarkReadEnum } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { MARK_READ_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { ProEventsBanner } from "~/pro-events/ProEventsBanner"

export const EventsScreen = () => {
  const community = useCommunity()
  const ccls = useCommunityClassname()

  const [runMarkRead] = useSafeMutation(MARK_READ_MUTATION)

  useEffect(() => {
    runMarkRead({
      variables: {
        input: {
          target: MarkReadEnum.Events,
        },
      },
    })
  }, [runMarkRead])

  const CALENDAR_VARIANTS: Record<CommunitySlug, "light" | "dark"> = {
    [CommunitySlug.Safespace]: "light",
    [CommunitySlug.Boardroom]: "dark",
    [CommunitySlug.Marketingland]: "dark",
    [CommunitySlug.Gotomillions]: "dark",
  }

  return (
    <div className="flex flex-col w-full">
      <ProEventsBanner />
      <a
        className="py-4"
        href={community.lumaEventsUrl!}
        target="_blank"
        rel="noreferrer"
      >
        <h1 className="text-xl tracking-wide text-foreground">
          See All Events
        </h1>
      </a>
      <div>
        <iframe
          title="Embedded Events"
          src={
            community.lumaCalendarUrl +
            `?lt=${CALENDAR_VARIANTS[community.slug]}`
          }
          className={cn(
            "rounded-lg w-full h-screen border mb-10",
            ccls({
              [CommunitySlug.Boardroom]: "border-gray-500",
              default: "border-mercury",
            })
          )}
          allowFullScreen={true}
          aria-hidden={false}
          tabIndex={0}
        ></iframe>
      </div>
    </div>
  )
}
