import { gql } from "~/__generated__"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { useQuery } from "@apollo/client"
import { Error } from "~/ui/Error"
import { Skeleton } from "~/ui/skeleton"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { Card } from "~/ui/card"
import { LeadsSearch } from "./LeadsSearch"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { useState, useMemo, useEffect } from "react"

const LEADS_QUERY = gql(`
  query Leads($first: Int, $after: String, $searchTerm: String, $sort: UserSortEnum, $source: String, $variationParam: String) {
    leads(first: $first, after: $after, searchTerm: $searchTerm, sort: $sort, source: $source, variationParam: $variationParam) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          email
          source
          variationParam
        }
      }
    }
  }
`)

const PAGE_SIZE = 10

export const LeadsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get("q") || ""
  )
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400)

  const headers = [
    { label: "Name", isPinned: true },
    { label: "Email" },
    { label: "Source" },
    { label: "Variant" },
  ]

  const queryOptions = useMemo(() => {
    const options: any = { first: PAGE_SIZE }
    if (debouncedSearchTerm) options.searchTerm = debouncedSearchTerm
    return { variables: options }
  }, [debouncedSearchTerm])

  const {
    data: currentData,
    previousData,
    loading,
    error,
    fetchMore,
  } = useQuery(LEADS_QUERY, {
    ...queryOptions,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  })

  const data = currentData || previousData

  const leads = useMemo(() => {
    return data?.leads.edges.map((e) => e.node) || []
  }, [data])

  const hasNextPage = !!data?.leads.pageInfo.hasNextPage
  const totalCount = data?.leads.totalCount || 0

  const memoizedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString())
    if (debouncedSearchTerm !== params.get("q")) {
      if (debouncedSearchTerm === "") {
        params.delete("q")
      } else {
        params.set("q", debouncedSearchTerm)
      }
    }
    return params
  }, [debouncedSearchTerm, searchParams])

  useEffect(() => {
    setSearchParams(memoizedSearchParams)
  }, [memoizedSearchParams, setSearchParams])

  return (
    <>
      <div className="flex justify-center items-center gap-4 mb-4">
        <div className="flex-grow">
          <LeadsSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
      </div>

      {error ? (
        <Error message="Error loading leads." />
      ) : (
        <>
          {leads.length > 0 && (
            <div className="text-sm text-gray-500 mb-4">
              {totalCount} leads found
            </div>
          )}

          <Card>
            <Table className="rounded-tl-2xl">
              <TableHeader>
                <TableRow className="group rounded-tl-2xl">
                  {headers.map((header) => (
                    <TableHead
                      key={header.label}
                      className={
                        header.isPinned
                          ? "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent rounded-tl-2xl"
                          : ""
                      }
                    >
                      {header.label}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {leads.map((lead) => (
                  <TableRow key={lead.id}>
                    <TableCell>{lead.name || "-"}</TableCell>
                    <TableCell>{lead.email || "-"}</TableCell>
                    <TableCell>{lead.source || "-"}</TableCell>
                    <TableCell>{lead.variationParam || "-"}</TableCell>
                  </TableRow>
                ))}
                {loading &&
                  Array.from({ length: 10 }).map((_, index) => (
                    <TableRow key={index}>
                      {headers.map((header) => (
                        <TableCell key={header.label}>
                          <Skeleton className="w-full h-[16px]" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Card>
        </>
      )}

      <div className="p-16 w-full flex items-center justify-center">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: {
                after: data?.leads.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev
                return {
                  leads: {
                    ...fetchMoreResult.leads,
                    edges: [
                      ...prev.leads.edges,
                      ...fetchMoreResult.leads.edges,
                    ],
                  },
                }
              },
            })
          }
          canLoadMore={!loading && hasNextPage}
          loadingText="Loading more leads..."
          loading={loading && leads.length > 0 && hasNextPage}
        />
      </div>
    </>
  )
}
