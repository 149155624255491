import { cn } from "~/lib/utils"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { CommunitySlug, FeatureFlagEnum } from "~/__generated__/graphql"
import { useCommunity } from "~/community/useCommunity"
import { FeatureFlag } from "~/common/FeatureFlag"

const COMMUNITY_BIO_ROWS: Record<CommunitySlug, number> = {
  [CommunitySlug.Safespace]: 3,
  [CommunitySlug.Boardroom]: 4,
  [CommunitySlug.Marketingland]: 4,
  [CommunitySlug.Gotomillions]: 4,
}

const COMMUNITY_BIO_LENGTH: Record<CommunitySlug, number> = {
  [CommunitySlug.Safespace]: 200,
  [CommunitySlug.Boardroom]: 400,
  [CommunitySlug.Marketingland]: 400,
  [CommunitySlug.Gotomillions]: 400,
}

export const OnboardingBioField = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const { t } = useTranslation("onboarding")
  const community = useCommunity()
  const bioLength = COMMUNITY_BIO_LENGTH[community.slug]

  const hasNewOnboarding = FeatureFlag.get(FeatureFlagEnum.NewOnboarding)

  return (
    <label className="onboarding-field">
      <div
        className={cn(
          "relative border border-borderColor rounded-lg p-4 pb-12 bg-white",
          {
            "border-frenchRose": !!errors.bio,
            "border-highlight bg-card-highlight-background":
              !watch("bio") && hasNewOnboarding,
          }
        )}
      >
        <textarea
          maxLength={bioLength}
          rows={COMMUNITY_BIO_ROWS[community.slug]}
          placeholder={t("bioFieldPlaceholder")}
          className={cn(
            "w-full p-0 text-dark-gray border-none resize-none focus:[box-shadow:none] placeholder-placeholder",
            {
              "border-highlight bg-card-highlight-background":
                !watch("bio") && hasNewOnboarding,
            }
          )}
          {...register("bio", {
            required: "Bio is required",
            maxLength: bioLength,
          })}
        />
        <div className="absolute right-4 bottom-4 text-[14px] text-[#b3b3b3]">
          {bioLength - (watch("bio")?.length || 0)}/{bioLength} characters left.
        </div>
      </div>
    </label>
  )
}
