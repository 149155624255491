import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  applicationLandingPath,
  signInTokenPath,
  signUpPath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/ui/button"
import { OnboardingFieldLabel, SignInContent } from "~/ui/Onboarding"
import errorIcon from "../images/error-screen.png"
import { OnboardingLayout } from "../layouts/OnboardingLayout"
import { Trans, useTranslation } from "react-i18next"
import { AgreementsSentence } from "~/auth/AgreementsSentence"
import { Link } from "~/ui/Link"
import { FeatureFlag } from "~/common/FeatureFlag"
import { FeatureFlagEnum } from "~/__generated__/graphql"

const SEND_MAGIC_LINK_MUTATION = gql(`
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      sessionId
    }
  }
`)

export const SignInScreen = () => {
  const [sendMagicLink, { loading }] = useSafeMutation(SEND_MAGIC_LINK_MUTATION)
  const [email, setEmail] = useState("")
  const [linkError, setLinkError] = useState("")
  const navigate = useNavigate()
  const { t } = useTranslation("signin")

  const onSubmit = async () => {
    const { data } = await sendMagicLink({
      variables: {
        input: {
          email,
        },
      },
    })

    if (data?.sendMagicLink.sessionId) {
      setLinkError("")
      navigate(signInTokenPath({ sessionId: data.sendMagicLink.sessionId }))
    } else if (data?.sendMagicLink) {
      setLinkError("Whoops, we didn't find that email")
    } else {
      setLinkError("Error Sending Magic Link")
    }
  }

  return (
    <OnboardingLayout step={0}>
      <SignInContent>
        <div className="sign-in-content__header">{t("signinTitle")}</div>
        <div className="mt-6">
          <Trans i18nKey="signinSubtitle" ns="signin" />
        </div>
        <div className="sign-in-content__form-container">
          {linkError !== "" && (
            <div className="py-3 px-4 mb-4 flex bg-frenchRose bg-opacity-5 border-[1px] border-frenchRose text-[red] font-semibold items-center rounded-lg">
              <img
                src={errorIcon}
                alt="Error"
                className="mr-4 w-[44px] h-[44px]"
              />
              <div className="flex-1 text-left flex flex-col gap-2">
                {linkError}
                {linkError === "Whoops, we didn't find that email" && (
                  <>
                    {FeatureFlag.get(FeatureFlagEnum.SignupInApp) ? (
                      <div className="font-normal text-xs">
                        Not a member yet?{" "}
                        <Link to={`${signUpPath.pattern}?email=${email}`}>
                          Sign Up
                        </Link>
                      </div>
                    ) : (
                      <div className="font-normal text-xs">
                        Use the email you entered in Stripe to submit membership
                        dues
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <form>
            <label className="onboarding-field">
              <OnboardingFieldLabel className="text-dark-gray">
                Email Address
                <span className="text-highlight">*</span>
              </OnboardingFieldLabel>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="onboarding-field__input text-dark-gray"
                placeholder="Enter your email"
              />
            </label>
            <Button
              className="w-full mt-9"
              variant="onboarding"
              onClick={onSubmit}
              disabled={email.length === 0 || loading}
              type="submit"
            >
              Sign In
            </Button>
          </form>
          <div className="sign-in-callout">
            Not a member yet?{" "}
            <Link
              href={
                FeatureFlag.get(FeatureFlagEnum.NewOnboarding)
                  ? applicationLandingPath.pattern
                  : signUpPath.pattern
              }
            >
              Sign Up
            </Link>
          </div>
          <AgreementsSentence preface="By creating an account you agree with our" />
        </div>
      </SignInContent>
    </OnboardingLayout>
  )
}
