import { Transition } from "@headlessui/react"
import {
  BarChart,
  BookMarked,
  Bug,
  CalendarDays,
  CircleCheck,
  CircleHelp,
  CircleUserRound,
  FlaskConical,
  Hammer,
  HeartHandshake,
  Layout,
  Mail,
  MessageSquareCode,
  MessagesSquare,
  Search,
  Users,
} from "lucide-react"
import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import {
  Community,
  CommunitySlug,
  FeatureEnum,
  FeatureFlagEnum,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { FeatureFlag } from "~/common/FeatureFlag"
import {
  adminAdministratorsPath,
  adminApplicationsPath,
  adminAutoMessagesPath,
  adminChannelsPath,
  adminDashboardPath,
  adminDebugSettingsPath,
  adminEmailSettingsPath,
  adminExperimentsPath,
  adminGroupsPath,
  adminHrisPath,
  adminIntroductionsPath,
  adminLibraryPath,
  adminMembersPath,
  adminLeadsPath,
  adminProEventsPath,
  adminResearchToolPath,
  adminScreenVariantsPath,
  feedPath,
} from "~/common/paths"
import { CommunityLogo } from "~/community/CommunityLogo"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import { cn } from "~/lib/utils"
import { Link } from "~/ui/Link"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
  useSidebar,
} from "~/ui/sidebar"
import { SimpleTooltip } from "~/ui/SimpleTooltip"

export const AdminLayout = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (!currentUser.admin) {
      navigate(feedPath.pattern)
    }
  }, [currentUser, navigate])

  useEffect(() => {
    document.body.classList.add("admin")
    document.body.classList.remove("dark")
    return () => {
      document.body.classList.remove("admin")
    }
  }, [])

  return (
    <div className="bg-background text-foregound">
      <UserDialogContextProvider>
        <SidebarProvider>
          <AdminSidebar />
          <main className="w-full min-h-screen pb-16">
            <SidebarTrigger className="w-4 h-4" />

            <div className="container mx-auto px-8">
              <Outlet />
            </div>
          </main>
        </SidebarProvider>
      </UserDialogContextProvider>
    </div>
  )
}

const navigation = [
  {
    label: "Navigation",
    items: [
      {
        label: "Dashboard",
        icon: Layout,
        to: adminDashboardPath.pattern,
      },
      {
        label: "Research Tool",
        icon: Search,
        to: adminResearchToolPath({ "activeTab?": "" }),
        show: ({ community }: { community: Pick<Community, "slug"> }) => {
          return (
            FeatureFlag.get(FeatureFlagEnum.ResearchTool) &&
            community?.slug === CommunitySlug.Boardroom
          )
        },
      },
    ],
  },
  {
    label: "User Management",
    items: [
      {
        label: "Applications",
        icon: CircleCheck,
        to: adminApplicationsPath({ "activeTab?": "" }),
      },
      {
        label: "Members",
        icon: Users,
        to: adminMembersPath({ "activeTab?": "all" }),
      },
      {
        label: "Leads",
        icon: CircleHelp,
        to: adminLeadsPath.pattern,
      },
      {
        label: "Administrators",
        icon: CircleUserRound,
        to: adminAdministratorsPath.pattern,
      },
    ],
  },
  {
    label: "Member Experience",
    items: [
      {
        label: "Auto Messages",
        icon: MessageSquareCode,
        to: adminAutoMessagesPath({ "activeTab?": "" }),
      },
      {
        label: "Introductions",
        icon: HeartHandshake,
        to: adminIntroductionsPath.pattern,
      },
      {
        label: "Events",
        icon: CalendarDays,
        to: adminProEventsPath.pattern,
      },
      {
        label: "Channels & Groups",
        icon: MessagesSquare,
        to: adminChannelsPath.pattern,
        isActive: (location: string) => {
          return (
            location.startsWith(adminChannelsPath.pattern) ||
            location.startsWith(adminGroupsPath.pattern)
          )
        },
      },
      {
        label: "Library",
        icon: BookMarked,
        to: adminLibraryPath.pattern,
      },
      {
        label: "HRIS",
        icon: BarChart,
        to: adminHrisPath.pattern,
        show: ({ community }: { community: Pick<Community, "features"> }) => {
          return community.features?.includes(FeatureEnum.Benchmark)
        },
      },
      {
        label: "Experiments",
        icon: FlaskConical,
        to: adminExperimentsPath.pattern,
      },
    ],
  },
  {
    label: "Settings",
    items: [
      {
        label: "Debug Settings",
        icon: Bug,
        to: adminDebugSettingsPath.pattern,
      },
      {
        label: "Manage Emails",
        icon: Mail,
        to: adminEmailSettingsPath.pattern,
      },
      {
        label: "Screen Variants",
        icon: Hammer,
        to: adminScreenVariantsPath.pattern,
      },
    ],
  },
]

const AdminSidebar = () => {
  const community = useCommunity()
  const location = useLocation()
  const { state } = useSidebar()
  const ccls = useCommunityClassname()

  const isActive = (item: {
    to: string
    isActive?: (location: string) => boolean
  }) => {
    if (item.isActive) {
      return item.isActive(location.pathname)
    } else {
      return location.pathname.startsWith(item.to)
    }
  }

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <Link
          to={feedPath.pattern}
          className="h-24 relative grid place-content-center items-center"
        >
          <Transition
            show={state === "collapsed"}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-content" style={{ gridArea: "1 / 1" }}>
              <CommunityLogo variant="square" className="w-8 h-8" />
            </div>
          </Transition>
          <Transition
            show={state === "expanded"}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-content" style={{ gridArea: "1 / 1" }}>
              <CommunityLogo
                variant="default"
                className={cn(
                  "w-full h-auto p-4 no-blur",
                  ccls({
                    [CommunitySlug.Gotomillions]: "text-black",
                    default: "",
                  })
                )}
              />
            </div>
          </Transition>
        </Link>
      </SidebarHeader>
      <SidebarContent>
        {navigation.map((group) => (
          <SidebarGroup key={group.label}>
            <SidebarGroupLabel className={cn("whitespace-nowrap")}>
              {group.label}
            </SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {group.items
                  .filter((item) => !item.show || item.show({ community }))
                  .map((item) => (
                    <SidebarMenuItem key={item.label}>
                      <SidebarMenuButton
                        asChild
                        className="whitespace-nowrap"
                        isActive={isActive(item)}
                      >
                        {item.label === "Research Tool" ? (
                          <a href={item.to} className="adminNav">
                            <SimpleTooltip
                              content={item.label}
                              side="right"
                              cursor="pointer"
                              enabled={state === "collapsed"}
                            >
                              <item.icon size={16} />
                            </SimpleTooltip>
                            <span
                              className={cn(
                                "transition-opacity duration-200",
                                state === "collapsed" && "opacity-0"
                              )}
                            >
                              {item.label}
                            </span>
                          </a>
                        ) : (
                          <Link to={item.to} variant="adminNav">
                            <SimpleTooltip
                              content={item.label}
                              side="right"
                              cursor="pointer"
                              enabled={state === "collapsed"}
                            >
                              <item.icon size={16} />
                            </SimpleTooltip>
                            <span
                              className={cn(
                                "transition-opacity duration-200",
                                state === "collapsed" && "opacity-0"
                              )}
                            >
                              {item.label}
                            </span>
                          </Link>
                        )}
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        ))}
      </SidebarContent>
      <SidebarFooter />
    </Sidebar>
  )
}
