import { useCommunity } from "~/community/useCommunity"
import { Button } from "~/ui/button"
import { Link } from "~/ui/Link"
import { Scroll, CalendarPlus, Mic } from "lucide-react"
import { Card, CardContent } from "~/ui/card"
import { cn } from "~/lib/utils"

const CTACard = ({
  type,
  ctaData,
}: {
  type: string
  ctaData: { url: string; copy: string }
}) => {
  const Icon = () => {
    switch (type) {
      case "newsletter":
        return <Scroll className="h-6 w-6 text-white" />
      case "events":
        return <CalendarPlus className="h-6 w-6 text-white" />
      case "podcast":
        return <Mic className="h-6 w-6 text-white" />
      default:
        return null
    }
  }

  const getButtonText = () => {
    switch (type) {
      case "newsletter":
        return "Read"
      case "events":
        return "RSVP"
      case "podcast":
        return "Listen"
      default:
        return "View"
    }
  }

  return (
    <Card className="w-full" variant="compact">
      <CardContent className="pt-6 flex flex-col h-full">
        <div className="flex items-center gap-4 mb-4">
          <div className="w-10 h-10 rounded-full bg-highlight flex items-center justify-center text-white">
            <Icon />
          </div>
          <h3 className="font-medium capitalize">{type}</h3>
        </div>
        <p className="text-gray-600 mb-auto">{ctaData.copy}</p>
        <Link
          href={ctaData.url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-4"
        >
          <Button className="px-16" variant="application" size="xl">
            {getButtonText()}
          </Button>
        </Link>
      </CardContent>
    </Card>
  )
}

export const ProcessingCTAs = () => {
  const community = useCommunity()
  const ctas = community.applicationProcessingCtas || {}

  type CTAData = {
    url: string
    copy: string
  }

  const ctaCount = Object.keys(ctas).length

  return (
    <div
      className={cn(
        "grid grid-cols-1 gap-6 max-w-5xl w-full mt-8 mx-auto justify-center px-6",
        ctaCount === 2 && "md:grid-cols-2",
        ctaCount === 3 && "lg:grid-cols-3 md:grid-cols-2"
      )}
    >
      {Object.entries(ctas as Record<string, CTAData>).map(
        ([type, ctaData]) => {
          if (!ctaData?.url) return null
          return <CTACard key={type} type={type} ctaData={ctaData} />
        }
      )}
    </div>
  )
}
