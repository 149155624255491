import { CircleHelp } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { LeadsTable } from "~/admin/leads/LeadsTable"

export const AdminLeadsScreen = () => {
  return (
    <>
      <AdminHeader title="Leads" Icon={CircleHelp} />
      <LeadsTable />
    </>
  )
}
