import { Dispatch, SetStateAction, useState } from "react"
import { cn } from "~/lib/utils"
import { Controller, useFormContext } from "react-hook-form"
import Dropzone from "react-dropzone"
import { directImageUpload } from "~/common/directImageUpload"
import { Button } from "~/ui/button"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import UploadPhotoIcon from "~/images/icons/upload-photo.svg?react"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  FeatureFlagEnum,
} from "~/__generated__/graphql"
import { FieldPointer, OnboardingFieldLabel } from "~/ui/Onboarding"
import { useCommunityClassname } from "~/community/useCommunity"
import { FeatureFlag } from "~/common/FeatureFlag"

export const OnboardingPhotoField = ({
  value,
  onChange,
  variant,
}: {
  value: string | null
  onChange: Dispatch<SetStateAction<string | null>>
  variant?: string
}) => {
  const { setValue, control } = useFormContext()
  const [uploading, setUploading] = useState<boolean>(false)
  const { logEvent } = useLogEvent()
  const ccls = useCommunityClassname()

  const removePhoto = (e: React.MouseEvent<HTMLElement>) => {
    onChange(null)
    setValue("photo", null, { shouldDirty: true })
    e.preventDefault()
  }

  const hasNewOnboarding = FeatureFlag.get(FeatureFlagEnum.NewOnboarding)

  return (
    <Controller
      name="photo"
      control={control}
      render={() => (
        <Dropzone
          noClick
          onDrop={async (acceptedFiles) => {
            setUploading(true)
            const blob = await directImageUpload(acceptedFiles[0])
            onChange(URL.createObjectURL(acceptedFiles[0]))
            setValue("photo", blob.signedId, { shouldDirty: true })
            setUploading(false)
            logEvent(AhoyEventTypeEnum.HeadshotAdded)
          }}
        >
          {({
            getRootProps,
            getInputProps,
            open,
            isDragActive,
            acceptedFiles,
          }) => (
            <label className="onboarding-field mt-4">
              <OnboardingFieldLabel>
                <FieldPointer show={!value && hasNewOnboarding}>
                  Upload Photo
                </FieldPointer>
              </OnboardingFieldLabel>
              <div
                className={cn(
                  "flex flex-col lg:flex-row w-full border-highlight border-dashed border rounded-lg p-6 items-center text-center lg:text-left",
                  variant === "onboarding"
                    ? "border-highlight"
                    : "border-borderColor",
                  {
                    "border-solid border-highlight bg-card-highlight-background":
                      !value && variant !== "onboarding" && hasNewOnboarding,
                  }
                )}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {uploading ? (
                  <div className="w-[64px] h-[64px] flex justify-center items-center">
                    <LoadingIndicator />
                  </div>
                ) : value ? (
                  <img
                    src={value}
                    alt=""
                    className="w-[64px] h-[64px] object-cover rounded-full"
                  />
                ) : (
                  <div
                    className={cn(
                      "w-[64px] h-[64px] flex justify-center items-center rounded-full",
                      ccls({
                        [CommunitySlug.Marketingland]: "bg-marketingland-blue",
                        default: "bg-highlight",
                      })
                    )}
                  >
                    <UploadPhotoIcon />
                  </div>
                )}
                <div className="flex-1 mx-6 my-4 lg:my-0">
                  <p className="font-semibold">
                    <button type="button" onClick={open} className="underline">
                      Click to upload
                    </button>{" "}
                    or drag and drop
                  </p>
                  <span
                    className={cn(
                      "text-xs",
                      variant === "onboarding"
                        ? "text-foreground"
                        : "text-dark-gray"
                    )}
                  >
                    Maximum file size 50 MB.
                  </span>
                  {value && (
                    <div
                      className="text-xs cursor-pointer text-highlight"
                      onClick={removePhoto}
                    >
                      Remove
                    </div>
                  )}
                </div>
                <Button
                  variant={variant === "onboarding" ? "onboarding" : "default"}
                  onClick={open}
                  className="px-6"
                  type="button"
                >
                  Choose Photo
                </Button>
              </div>
            </label>
          )}
        </Dropzone>
      )}
    />
  )
}
