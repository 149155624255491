import { Control, useFormContext } from "react-hook-form"
import { SelectField } from "~/components/forms/SelectField"
import { TextField } from "~/components/forms/TextField"
import { FormValues } from "../ApplicationLandingScreen"
import { cn } from "~/lib/utils"
import { Translation } from "~/common/Translation"
import { camelCase } from "change-case"
import { CommunitySlug, ScreenEnum } from "~/__generated__/graphql"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { Coffee } from "lucide-react"

interface MadlibsQuestionsProps {
  control: Control<FormValues>
}

const QUESTIONS: Record<
  CommunitySlug,
  {
    yearsOfExperience: { label: string; value: string }[]
    cupsOfCoffee?: { label: string; value: string }[]
    jobFunction?: { label: string; value: string }[]
    manageContribute?: { label: string; value: string }[]
    teamSize?: { label: string; value: string }[]
    organizationType?: { label: string; value: string }[]
    business?: { label: string; value: string }[]
    ecomIndustry?: { label: string; value: string }[]
    revenue?: { label: string; value: string }[]
  }
> = {
  [CommunitySlug.Safespace]: {
    yearsOfExperience: [
      { label: "0 - 5", value: "0-5" },
      { label: "6 - 10", value: "6-10" },
      { label: "10+", value: "10+" },
    ],
    jobFunction: [
      {
        label: "Compensation & Benefits",
        value: "Compensation & Benefits",
      },
      { label: "Compliance & Legal", value: "Compliance & Legal" },
      { label: "Employee Engagement", value: "Employee Engagement" },
      {
        label: "Employee Training & Development",
        value: "Employee Training & Development",
      },
      { label: "Finance & Analytics", value: "Finance & Analytics" },
      {
        label: "HR Strategy & Organization Development",
        value: "HR Strategy & Organization Development",
      },
      { label: "Payroll Admin", value: "Payroll Admin" },
      {
        label: "Recruitment & Talent Acquisition",
        value: "Recruitment & Talent Acquisition",
      },
      { label: "Other", value: "Other" },
    ],
    manageContribute: [
      { label: "Manage", value: "manage" },
      { label: "Contribute to", value: "contribute_to" },
      { label: "Manage & Contribute to", value: "manage_and_contribute_to" },
    ],
    teamSize: [
      { label: "1", value: "1" },
      { label: "2-5", value: "2-5" },
      { label: "6-20", value: "6-20" },
      { label: "20+", value: "20+" },
    ],
  },
  [CommunitySlug.Boardroom]: {
    yearsOfExperience: [
      { label: "0 - 3", value: "0-3" },
      { label: "4 - 10", value: "4-10" },
      { label: "10+", value: "10+" },
    ],
    jobFunction: [
      { label: "Consulting", value: "Consulting" },
      { label: "Corporate Development", value: "Corporate Development" },
      { label: "Data & Analytics", value: "Data & Analytics" },
      { label: "Direct Patient Care", value: "Direct Patient Care" },
      { label: "Engineering", value: "Engineering" },
      { label: "Finance", value: "Finance" },
      { label: "Higher Education", value: "Higher Education" },
      { label: "Human Resources", value: "Human Resources" },
      { label: "Information Technology", value: "Information Technology" },
      { label: "Legal", value: "Legal" },
      { label: "Marketing", value: "Marketing" },
      { label: "Media", value: "Media" },
      { label: "Operations", value: "Operations" },
      { label: "Public Relations", value: "Public Relations" },
      { label: "Regulartory Affairs", value: "Regulartory Affairs" },
      { label: "Research & Development", value: "Research & Development" },
      {
        label: "Sales & Business Development",
        value: "Sales & Business Development",
      },
      { label: "Strategy", value: "Strategy" },
      { label: "Supply Chain Management", value: "Supply Chain Management" },
    ],
    organizationType: [
      { label: "Consulting Firm", value: "Consulting Firm" },
      {
        label: "Durable Medical Equipment",
        value: "Durable Medical Equipment",
      },
      { label: "Government Health Agency", value: "Government Health Agency" },
      { label: "Health Tech Startup", value: "Health Tech Startup" },
      {
        label: "Hospital or Health System",
        value: "Hospital or Health System",
      },
      { label: "Life Science Company", value: "Life Science Company" },
      { label: "Medical Device Company", value: "Medical Device Company" },
      { label: "Payor Org", value: "Payor Org" },
      { label: "Pharmacy & Retailer", value: "Pharmacy & Retailer" },
      { label: "Provider Org", value: "Provider Org" },
      { label: "Telehealth Provider", value: "Telehealth Provider" },
      { label: "Venture Capital Firm", value: "Venture Capital Firm" },
      { label: "Other", value: "Other" },
    ],
  },
  [CommunitySlug.Marketingland]: {
    yearsOfExperience: [
      { label: "0 - 5", value: "0-5" },
      { label: "6 - 10", value: "6-10" },
      { label: "10+", value: "10+" },
    ],
    organizationType: [
      { label: "at a brand", value: "at a brand" },
      { label: "at an agency", value: "at an agency" },
      {
        label: "as Freelancer or Consultant",
        value: "as Freelancer or Consultant",
      },
    ],
    business: [
      { label: "B2B", value: "B2B" },
      { label: "B2C", value: "B2C" },
    ],
    teamSize: [
      { label: "just me!", value: "just me!" },
      { label: "2 - 5", value: "2-5" },
      { label: "6 - 20", value: "6-20" },
      { label: "21+", value: "21+" },
    ],
    cupsOfCoffee: [
      { label: "0 cups", value: "0 cups" },
      { label: "1 cup", value: "1 cup" },
      { label: "2 cups", value: "2 cups" },
      { label: "3 cups", value: "3 cups" },
      { label: "4 cups", value: "4 cups" },
      { label: "5+ cups", value: "5+ cups" },
    ],
  },
  [CommunitySlug.Gotomillions]: {
    yearsOfExperience: [
      { label: "0 - 4", value: "0-4" },
      { label: "5 - 10", value: "5-10" },
      { label: "10+", value: "10+" },
    ],
    jobFunction: [
      { label: "account based marketing", value: "account based marketing" },
      { label: "amazon", value: "amazon" },
      { label: "b2b demand gen", value: "b2b demand gen" },
      { label: "brand marketing", value: "brand marketing" },
      { label: "content marketing", value: "content marketing" },
      { label: "copywriting", value: "copywriting" },
      { label: "data analytics", value: "data analytics" },
      { label: "design", value: "design" },
      { label: "digital marketing", value: "digital marketing" },
      { label: "ecom management", value: "ecom management" },
      { label: "event / field marketing", value: "event / field marketing" },
      { label: "go-to-market", value: "go-to-market" },
      { label: "growth marketing", value: "growth marketing" },
      { label: "influencer marketing", value: "influencer marketing" },
      { label: "other", value: "other" },
      { label: "partner marketing", value: "partner marketing" },
      { label: "PR", value: "PR" },
      { label: "product marketing", value: "product marketing" },
      { label: "retention marketing", value: "retention marketing" },
      { label: "revenue ops", value: "revenue ops" },
      { label: "social media marketing", value: "social media marketing" },
    ],
    manageContribute: [],
    teamSize: [],
    organizationType: [
      { label: "ecom brand", value: "ecom brand" },
      { label: "retail brand", value: "retail brand" },
      { label: "onmichannel brand", value: "onmichannel brand" },
      { label: "agency", value: "agency" },
      {
        label: "software or service provider",
        value: "software or service provider",
      },
      { label: "marketplace (added)", value: "marketplace (added)" },
    ],
    ecomIndustry: [
      { label: "Apparel & Fashion", value: "Apparel & Fashion" },
      { label: "Beauty & Cosmetics", value: "Beauty & Cosmetics" },
      { label: "Books & Stationary", value: "Books & Stationary" },
      { label: "Construction Goods", value: "Construction Goods" },
      { label: "Consumer Packaged Goods", value: "Consumer Packaged Goods" },
      { label: "Electronics", value: "Electronics" },
      { label: "Fitness", value: "Fitness" },
      { label: "Food & Beverage", value: "Food & Beverage" },
      { label: "Health", value: "Health" },
      { label: "Home Improvement", value: "Home Improvement" },
      { label: "Kids & Toys", value: "Kids & Toys" },
      { label: "Luxury Goods", value: "Luxury Goods" },
      { label: "Pet Suplies", value: "Pet Suplies" },
      { label: "Shoes", value: "Shoes" },
      { label: "Sporting Goods", value: "Sporting Goods" },
      { label: "Travel Goods", value: "Travel Goods" },
      { label: "Wellness", value: "Wellness" },
      { label: "Other", value: "Other" },
    ],
    revenue: [
      { label: "$0-$100k", value: "$0-$100k" },
      { label: "$101k-$999k", value: "$101k-$999k" },
      { label: "$1M-$10M", value: "$1M-$10M" },
      { label: "$11M-$50M", value: "$11M-$50M" },
      { label: "$51M-$100M", value: "$51M-$100M" },
      { label: "$100M+", value: "$100M+" },
    ],
  },
}

const YearsOfExperienceInput = ({
  value,
  onClick,
}: {
  value: string
  onClick: (value: string) => void
}) => {
  const community = useCommunity()
  const { formState } = useFormContext()
  const ccls = useCommunityClassname()

  return (
    <div className="inline-block mx-1">
      <div
        className={cn(
          "flex gap-2 items-center",
          !!formState.errors.yearsOfExperience &&
            "ring-1 ring-offset-1 ring-red-500/80 rounded-full"
        )}
      >
        {QUESTIONS[community.slug].yearsOfExperience.map((option) => (
          <button
            type="button"
            key={option.value}
            onClick={() => onClick(option.value)}
            className={cn(
              "bg-white border border-slate-200 cursor-pointer rounded-full py-[6px] px-6 text-xs text-center",
              value === option.value &&
                ccls({
                  [CommunitySlug.Gotomillions]:
                    "bg-primary text-white border-primary",
                  default: "bg-highlight text-white border-highlight",
                }),
              ccls({
                [CommunitySlug.Gotomillions]: "hover:border-primary",
                default: "hover:border-highlight",
              })
            )}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}

const CupsOfCoffeeInput = ({
  value,
  onClick,
}: {
  value: string
  onClick: (value: string) => void
}) => {
  const community = useCommunity()
  const { formState } = useFormContext()

  return (
    <div
      className={cn(
        "inline-block px-2 translate-y-[3px]",
        !!formState.errors.cupsOfCoffee &&
          "ring-1 ring-offset-1 ring-red-500/80 rounded-full"
      )}
    >
      <div className="flex gap-1 items-center -pb-2">
        {QUESTIONS[community.slug].cupsOfCoffee?.map((option, index) => {
          const selectedIndex = QUESTIONS[
            community.slug
          ].cupsOfCoffee?.findIndex((opt) => opt.value === value)
          const isHighlighted =
            selectedIndex !== undefined && selectedIndex >= index

          return (
            <button
              type="button"
              key={option.value}
              onClick={() => onClick(option.value)}
              className={cn(
                "cursor-pointer",
                isHighlighted && "text-highlight"
              )}
            >
              <Coffee className="w-5 h-5" />
            </button>
          )
        })}
      </div>
    </div>
  )
}

export const MadlibsQuestions = ({ control }: MadlibsQuestionsProps) => {
  const community = useCommunity()
  const { setValue, watch, formState } = useFormContext()

  const yearsOfExperience = watch("yearsOfExperience")
  const setYearsOfExperience = (value: string) => {
    setValue("yearsOfExperience", value)
  }

  const cupsOfCoffee = watch("cupsOfCoffee")
  const setCupsOfCoffee = (value: string) => {
    setValue("cupsOfCoffee", value)
  }

  return (
    <div className="leading-[3rem]">
      {formState.errors.madlibsQuestions && (
        <p className="text-red-500 mb-4 text-sm">
          Please answer all questions.
        </p>
      )}
      <Translation
        ns={camelCase(ScreenEnum.ApplicationLandingScreen)}
        i18nKey="madlibs"
        components={{
          YearsOfExperienceInput: (
            <YearsOfExperienceInput
              value={yearsOfExperience}
              onClick={setYearsOfExperience}
            />
          ),
          CupsOfCoffeeInput: (
            <CupsOfCoffeeInput value={cupsOfCoffee} onClick={setCupsOfCoffee} />
          ),
          JobFunctionSelect: (
            <SelectField
              hideErrorMessage
              placeholder="Select your primary focus area"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="jobFunction"
              selectEntries={QUESTIONS[community.slug].jobFunction ?? []}
            />
          ),
          JobFunctionInput: (
            <TextField
              hideErrorMessage
              inputClassName="text-base sm:text-sm"
              containerClassName="inline-block space-y-0 mx-1"
              control={control}
              name="jobFunction"
              placeholder="Enter your speciality"
              label=""
            />
          ),
          JobTitleInput: (
            <TextField
              hideErrorMessage
              inputClassName="text-base sm:text-sm"
              containerClassName="inline-block space-y-0"
              control={control}
              name="jobTitle"
              placeholder="Enter your job title"
              label=""
            />
          ),
          RoleInput: (
            <SelectField
              hideErrorMessage
              placeholder="Select role"
              containerClassName="inline-block w-fit space-y-0"
              label=""
              control={control}
              name="manageContribute"
              selectEntries={QUESTIONS[community.slug].manageContribute ?? []}
            />
          ),
          TeamSizeInput: (
            <SelectField
              hideErrorMessage
              placeholder="Select team size"
              containerClassName="inline-block w-fit space-y-0 mx-1"
              label=""
              control={control}
              name="teamSize"
              selectEntries={QUESTIONS[community.slug].teamSize ?? []}
            />
          ),
          OrganizationTypeInput: (
            <SelectField
              hideErrorMessage
              placeholder="Select organization type"
              containerClassName="inline-block w-fit space-y-0 mx-1"
              label=""
              control={control}
              name="organizationType"
              selectEntries={QUESTIONS[community.slug].organizationType ?? []}
            />
          ),
          BusinessSelect: (
            <SelectField
              hideErrorMessage
              placeholder="-"
              containerClassName="inline-block w-fit space-y-0 min-w-[75px] mx-1"
              label=""
              control={control}
              name="business"
              selectEntries={QUESTIONS[community.slug].business ?? []}
            />
          ),
          EcomIndustrySelect: (
            <SelectField
              hideErrorMessage
              placeholder="-"
              containerClassName="inline-block w-fit space-y-0 min-w-[75px] mx-1"
              label=""
              control={control}
              name="ecomIndustry"
              selectEntries={QUESTIONS[community.slug].ecomIndustry ?? []}
            />
          ),
          RevenueSelect: (
            <SelectField
              hideErrorMessage
              placeholder="-"
              containerClassName="inline-block w-fit space-y-0 min-w-[75px] mx-1"
              label=""
              control={control}
              name="revenue"
              selectEntries={QUESTIONS[community.slug].revenue ?? []}
            />
          ),
        }}
      />
    </div>
  )
}
