import { EyeNoneIcon } from "@radix-ui/react-icons"
import { useState } from "react"
import { UserSortEnum, User_AdminFragment } from "~/__generated__/graphql"
import { UserDialog } from "~/directory/UserDialog"
import { Button } from "~/ui/button"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { Card, CardContent } from "~/ui/card"
import { Skeleton } from "~/ui/skeleton"
import { UsersTable } from "./UsersTable"
import { UserName } from "~/directory/UserName"
import { FitProfileDisplay } from "./FitProfileDisplay"
import { formatTime } from "~/common/formatDate"
import { parseISO } from "date-fns"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import { useConfirm } from "~/ui/Confirm"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { USER_ADMIN_UPDATE_MUTATION } from "~/screens/admin/AdminMembersScreen"

const UnfitApplicationsTableSkeleton = () => (
  <div className="flex flex-col gap-2">
    {Array.from({ length: PAGE_SIZE }).map((_, index) => (
      <div key={index}>
        <Card>
          <CardContent className="pt-4">
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 flex-grow">
                <Skeleton className="rounded-full w-[60px] h-[60px]" />
                <div className="flex flex-col gap-1">
                  <Skeleton className="w-[120px] h-[16px]" />
                  <Skeleton className="w-[100px] h-[16px]" />
                  <Skeleton className="w-[140px] h-[16px]" />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ))}
  </div>
)

type UserRowProps = {
  user: User_AdminFragment
}

const UserRow = ({ user }: UserRowProps) => {
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const [runUserUpdate] = useSafeMutation(USER_ADMIN_UPDATE_MUTATION)
  const showConfirm = useConfirm()

  const changeToFit = async () => {
    await showConfirm({
      title: `Reverse decision?`,
      body: (
        <div>
          <div className="mb-4">
            The user will be marked as fit and upon returning to the community,
            will be placed within the standard application flow. Are you sure
            you want to proceed?
          </div>
        </div>
      ),
      confirmText: "CONFIRM CHANGE",
      cancelText: "Cancel",
      onConfirm: async () => {
        const { errors } = await runUserUpdate({
          variables: {
            input: {
              userId: user.id,
              fit: true,
              fitnessDecisionCommunicatedAt: new Date().toISOString(),
            },
          },
        })
        if (errors) {
          displayErrors(errors)
        }
      },
    })
  }

  return (
    <>
      {userDialogOpen && (
        <UserDialog
          isOpen={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          user={user}
        />
      )}

      <Card>
        <CardContent className="pt-4">
          <div className="flex gap-2 flex-wrap items-center">
            <div className="flex gap-2 flex-grow">
              <div>
                <AvatarWithFallback user={user} size="2xl" />
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  <Button
                    variant="link"
                    size="inline"
                    className="text-primary"
                    onClick={() => setUserDialogOpen(true)}
                  >
                    <UserName user={user} />
                  </Button>
                  {user.hidden && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <EyeNoneIcon className="text-pretext-grey" />
                        </TooltipTrigger>
                        <TooltipContent>
                          User is hidden from the directory
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>

                <div className="text-2xs flex flex-col items-start gap-2">
                  <CopyToClipboard
                    text={user.email!}
                    className="text-2xs text-gray-500"
                  >
                    {user.email}
                  </CopyToClipboard>
                  <div>
                    Tier:{" "}
                    <strong>
                      {user.tier?.name || user.previousTier?.name || "N/A"}
                    </strong>
                  </div>
                  <div>
                    Application completed at:{" "}
                    <strong>
                      {user.fitApplicationSubmittedAt
                        ? formatTime(parseISO(user.fitApplicationSubmittedAt))
                        : "N/A"}
                    </strong>
                  </div>
                  <div className="text-pretext-light-gray text-2xs">
                    {user.jobTitle} - {user.companyName || "Unknown Company"} -{" "}
                    {user.place?.full || "Unknown Location"}
                  </div>
                  <FitProfileDisplay user={user} />
                </div>
              </div>
            </div>

            <div className="ml-auto">
              <Button
                variant="destructive"
                className="w-32 text-xs"
                onClick={changeToFit}
              >
                Change to Fit
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

const PAGE_SIZE = 10
const DEFAULT_SORT = UserSortEnum.ApplicationPriority

export const UnfitApplicationsTable = () => (
  <UsersTable
    withSearch
    variant="card"
    skeleton={<UnfitApplicationsTableSkeleton />}
    defaultSort={DEFAULT_SORT}
    queryOptions={{ unfitOnly: true }}
  >
    {(user) => <UserRow key={user.id} user={user} />}
  </UsersTable>
)
