import { useQuery } from "@apollo/client"
import { Check } from "lucide-react"
import { gql } from "~/__generated__"
import { useForm } from "react-hook-form"
import { useSafeMutation } from "~/common/useSafeMutation"
import { WaitingRoomHeader } from "../ABFitWaitingScreen"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import { Card, CardContent } from "~/ui/card"
import { Button } from "~/ui/button"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/ui/form"
import { AhoyEventTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"

const invitationFormSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  email: z.string().email("Invalid email address"),
})

type InvitationFormValues = z.infer<typeof invitationFormSchema>

export const Referral = () => {
  const ccls = useCommunityClassname()
  const { logEvent } = useLogEvent()
  const { data: invitationsData } = useQuery(INVITATIONS_QUERY)
  const invitations = invitationsData?.invitations?.nodes || []
  const form = useForm<InvitationFormValues>({
    resolver: zodResolver(invitationFormSchema),
    defaultValues: {
      firstName: "",
      email: "",
    },
  })

  const [createInvitation, { loading }] = useSafeMutation(
    INVITATION_CREATE_MUTATION,
    {
      refetchQueries: [{ query: INVITATIONS_QUERY }],
    }
  )

  const onSubmit = async (values: InvitationFormValues) => {
    const { errors, data } = await createInvitation({
      variables: {
        input: {
          firstName: values.firstName,
          email: values.email,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
    } else {
      logEvent(AhoyEventTypeEnum.InvitationSubmitted, {
        invitation_email: values.email,
        invitation_id: data?.invitationCreate?.invitation?.id,
      })
      toast.success("Invitation sent!")
      form.reset()
    }
  }

  return (
    <>
      <div className="flex items-center flex-col">
        <WaitingRoomHeader />
        <div className="font-sans font-light tracking-wide mb-5">
          Skip ahead in line by inviting a friend. Plus, you'll make their day.
        </div>

        <Card className="w-full max-w-2xl" variant="compact">
          <CardContent className="pt-6">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="flex-1">
                    <FormField
                      control={form.control}
                      name="firstName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="block font-normal tracking-wide text-sm">
                            First Name
                          </FormLabel>
                          <FormControl>
                            <input
                              type="text"
                              placeholder="Bill"
                              className={cn(
                                "w-full px-4 py-2 border border-pretext-light-gray rounded-md placeholder:text-pretext-light-gray placeholder:font-light placeholder:tracking-wide",
                                ccls({
                                  [CommunitySlug.Gotomillions]:
                                    "border-primary",
                                  default: "",
                                })
                              )}
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex-1">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="block font-normal tracking-wide text-sm">
                            Email Address
                          </FormLabel>
                          <FormControl>
                            <input
                              type="email"
                              placeholder="Sample@gmail.com"
                              className={cn(
                                "w-full px-4 py-2 border border-pretext-light-gray rounded-md placeholder:text-pretext-light-gray placeholder:font-light placeholder:tracking-wide",
                                ccls({
                                  [CommunitySlug.Gotomillions]:
                                    "border-primary",
                                  default: "",
                                })
                              )}
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                <div className="text-sm font-medium text-pretext-light-gray tracking-wide">
                  Your friend will receive a personal invitation to join the
                  community via email.
                  <br />
                  Don't worry, you'll also be cc'ed on the email.
                </div>

                <div className="flex justify-start">
                  <Button
                    type="submit"
                    className="px-8"
                    variant="application"
                    size="xl"
                    disabled={!form.formState.isValid || loading}
                    isLoading={loading}
                  >
                    Invite A Friend!
                  </Button>
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>

        {invitations.length > 0 && (
          <Card className="w-full max-w-2xl mt-8" variant="compact">
            <CardContent className="pt-6">
              <h3 className="font-medium mb-4">Sent Invitations</h3>
              <div className="divide-y">
                {invitations.map((invitation) => (
                  <div
                    key={invitation.id}
                    className="py-3 flex items-center justify-between"
                  >
                    <div className="flex items-center gap-2">
                      <div
                        className={cn(
                          "rounded-full p-1",
                          ccls({
                            [CommunitySlug.Gotomillions]: "bg-success",
                            default: "bg-highlight",
                          })
                        )}
                      >
                        <Check className="h-3 w-3 text-white" />
                      </div>
                      <span>{invitation.firstName}</span>
                    </div>
                    <span className="text-[#333333] font-light tracking-wide">
                      {invitation.email}
                    </span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </>
  )
}

const INVITATIONS_QUERY = gql(`
  query Invitations {
    invitations {
      nodes {
        id
        firstName
        email
        createdAt
      }
    }
  }
`)

const INVITATION_CREATE_MUTATION = gql(`
  mutation InvitationCreate($input: InvitationCreateInput!) {
    invitationCreate(input: $input) {
      invitation {
        id
        email
        firstName
      }
    }
  }
`)
