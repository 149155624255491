import { CommunityLogo } from "~/community/CommunityLogo"
import { Outlet } from "react-router-dom"
import React, { createContext, useContext } from "react"
import invariant from "tiny-invariant"
import { Link } from "~/ui/Link"
import { buttonVariants } from "~/ui/button"
import { cn } from "~/lib/utils"
import { CTAWrapper } from "~/layouts/CTALayout"

import { useCommunityClassname } from "~/community/useCommunity"
import { CommunitySlug } from "~/__generated__/graphql"
const ApplicationContext = createContext<
  | {
      setAction: (action?: React.ReactNode) => void
      setBanner: (banner?: React.ReactNode) => void
    }
  | undefined
>(undefined)

export const useApplicationLayout = () => {
  const context = useContext(ApplicationContext)
  invariant(
    context,
    "useApplicationLayout must be used within a ApplicationLayout"
  )
  return context
}

export const ApplicationLayout = () => {
  const ccls = useCommunityClassname()
  const [action, setAction] = React.useState<
    React.ReactNode | null | undefined
  >(
    <Link
      href="mailto:memberships@workweek.com"
      className={cn(
        buttonVariants(),
        ccls({
          [CommunitySlug.Gotomillions]: "bg-primary",
          default: "bg-highlight",
        })
      )}
    >
      Contact Us
    </Link>
  )
  const [banner, setBanner] = React.useState<
    React.ReactNode | null | undefined
  >(undefined)

  return (
    <ApplicationContext.Provider
      value={{
        setAction,
        setBanner,
      }}
    >
      <div className="min-h-screen text-foreground tracking-[0.5px] flex flex-col items-center">
        <div className="grid grid-cols-2 gap-4 sm:gap-0 sm:grid-cols-12 px-8 py-4 max-md:pt-9 md:h-[73px] border-b items-center w-full">
          <div className="sm:col-start-2 sm:col-span-10 flex items-center justify-center">
            <CommunityLogo className="max-h-[41px]" />
          </div>
          <div className="sm:col-start-12 sm:col-span-1 flex items-center justify-end">
            {action}
          </div>
        </div>

        <div className="mb-16 w-full">
          {banner && (
            <CTAWrapper dismissable={false} className="min-h-16">
              <div className="flex flex-col justify-center text-center gap-4 container tracking-[0.5px]">
                {banner}
              </div>
            </CTAWrapper>
          )}
        </div>

        <div className="container px-4 pb-8">
          <Outlet />
        </div>
      </div>
    </ApplicationContext.Provider>
  )
}
